<template>
  <!--begin::Modal - Create Api Key-->
  <div class="modal fade" id="approve_product" ref="createApprovalModalRef" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="approve_product_header">
          <!--begin::Modal title-->
          <h2>Approve Product</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Form-->
        <Form id="approve_product_form" class="w-100" style="width: 100%" @submit="submit" :validation-schema="validationSchema">
          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div class="scroll-y me-n7 pe-7" id="approve_product_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#approve_product_header" data-kt-scroll-wrappers="#approve_product_scroll" data-kt-scroll-offset="300px">

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-10 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Select Product</label>
                <!--end::Label-->
                <!--begin::Select-->
                <Field name="productId" data-control="select2" data-hide-search="true" data-placeholder="Select Product" class="form-select" as="select" v-model="apiData.productId" @change="getSKUs(apiData.productId)">
                  <option value="">Select Product</option>
                  <option v-for="productDetail in product" :value="productDetail.product_id" :key="productDetail">
                    {{productDetail.product_name}}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="productId" />
                  </div>
                </div>
                <!--end::Select-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-10 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Select SKU</label>
                <!--end::Label-->
                <!--begin::Select-->
                <Field name="sku" data-control="select2" data-hide-search="true" data-placeholder="Select SKU" class="form-select" as="select" v-model="apiData.skuID">
                  <option value="">Select SKU</option>
                  <option v-for="SKUs in skuData.value" :value="SKUs.product_sku_id" :key="SKUs">
                    {{SKUs.sku}}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="sku" />
                  </div>
                </div>
                <!--end::Select-->
              </div>
              <!--end::Input group-->
              
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer">
            <!--begin::Button-->
            <button ref="submitButtonRef" type="submit" value="approve" id="approve_product_submit" class="btn btn-primary">
              <span class="indicator-label"> Save </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
            <!--begin::Button-->
            <button type="reset" id="approve_product_cancel" @click="reset()" class="btn btn-secondary me-3" data-bs-dismiss="modal">Cancel</button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create Api Key-->
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import { useRouter } from "vue-router";
import useEventBus from '../../../composable/useEventBus'

export default defineComponent({
  name: "product-approval-modal",
  emit: ["statusUpdate"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    orderProductId: Number, 
    orderId: Number,
    productId: Number,
    product: Array,
    platform: String,
    productSku: String,
    wholeproductData: Object,
    isApproveArtSKU: String
  },
  setup(props, { emit }) {
    const { emitEvent } = useEventBus()
    const router = useRouter();
    const submitButtonRef = ref(null);
    const modalRef = ref(null);
    const createApprovalModalRef = ref(null);
    const fullScreenLoading = ref(false);
    const skuData = reactive([]);
    const apiData = ref({
      productId: "",
      setting_type:"",
      skuID:"",
    });
    const showSizeWithAddition = ref(false);
    const showSizeWithAdditionCombination = ref(false);

    //set additional option in particular product
    const setAdditionalOption = (id) => {
      props.wholeproductData.forEach((obj) => {
        if(id == obj.product_id){
          if(obj.additional_assign_data.length > 0){
            showSizeWithAddition.value = true;
          }else{
            showSizeWithAddition.value = false;
          }
          if(obj.additional_assign_data.length > 1){
              showSizeWithAdditionCombination.value = true;
          }else{
              showSizeWithAdditionCombination.value = false;
          }
        }
      });
      apiData.value.setting_type = "";
      apiData.value.skuID = "";
      skuData.value = [];
    }

    const getSKUs = (product_id) => {
      apiData.value.skuID = "";
      skuData.value = [];
      fullScreenLoading.value = true;
      ApiService.post('get-sku-for-pending-order', {
            product_id: product_id,
            platform: props.platform
          })
        .then(({ data }) => {
          if (data.data) {
            let skus = [];
            data.data.forEach((obj) => {
              skus.push({
                  product_sku_id: obj.product_sku_id,
                  sku: obj.sku,
              });
            });
            skuData.value = skus;
          }
          fullScreenLoading.value = false;
        })
        .catch((error) => {
          let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404 || status == 422) {
                router.push({ name: 'pending-orders'})
              }
            }
            fullScreenLoading.value = false;
        });
    }

    const validationSchema = Yup.object().shape({
      productId: Yup.string().required().label("Product"),
      sku: Yup.string().required().label("SKU"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");

        let formData = apiData.value;
        formData['orderId'] = props.orderId;
        formData['orderProductId'] = props.orderProductId;
        formData['platform'] = props.platform;
        formData['productSku'] = props.productSku;
        formData['isApproveArtSKU'] = props.isApproveArtSKU;
        ApiService.post('approve-product', formData)
          .then((response) => {
            if (response.data) {
              let status = response.status;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                notificationFire(message,'success');
              }
              if(response.data.data.approval) {
                emit("statusUpdate", response.data.data);
              }
              if(response.data.data.redirect && (props.isApproveArtSKU == 'Matched' || props.isApproveArtSKU == 'NotFound')) {
                getPendingOrdersCount();
                router.push({ name: 'pending-orders'})
              }
            }
            apiData.value.productId = "";
            apiData.value.setting_type = "";
            apiData.value.skuID = "";
            skuData.value = [];
            submitButtonRef.value.disabled = false;
            submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            hideModal(createApprovalModalRef.value);
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            else {
              let message = error.message;
              notificationFire(message,'error');
            }
            submitButtonRef.value.disabled = false;
            submitButtonRef.value.setAttribute("data-kt-indicator", "off");
          });
    };

    const reset = () => {
      setTimeout(() => 
        apiData.value.productId = "",
        apiData.value.skuID = "",
        skuData.value = [], 2000);
    }

    const getPendingOrdersCount = async () => {
      await ApiService.post("/pending-orders-count", {})
      .then(({ data }) => {
        if (data.pendingOrderProduct  != undefined) {
          emitEvent('counterUpdate',{"pendingOrders":data.pendingOrders})
        }
        if (data.pendingOrderProduct  != undefined) {
          emitEvent('counterUpdate',{"pendingOrderProducts":data.pendingOrderProduct})
        }
      })
      .catch((error) => {
        console.log(error);
        //Toast the error here
      });
    }

    return {
      apiData,
      validationSchema,
      submit,
      submitButtonRef,
      modalRef,
      createApprovalModalRef,
      showSizeWithAddition,
      showSizeWithAdditionCombination,
      setAdditionalOption,
      getSKUs,
      fullScreenLoading,
      skuData,
      getPendingOrdersCount,
      reset
    };
  },
});
</script>
