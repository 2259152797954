<template>
  <div class="d-flex flex-column gap-7 gap-lg-10" v-loading="fullscreenLoading">
    <div class="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
      <!--begin:::Tabs-->
      <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-lg-n2 me-auto">
        <!--begin:::Tab item-->
        <li class="nav-item">
          <a class="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_summary">Order Summary</a>
        </li>
        <!--end:::Tab item-->
        <!--begin:::Tab item-->
        <li class="nav-item" v-if="permission.isReadOrderHistoryAllowed">
          <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_history">Order History</a>
        </li>
        <!--end:::Tab item-->
      </ul>
      <!--end:::Tabs-->
    </div>

    <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
      <!--begin::Order details-->
      <div class="card card-flush py-4 flex-row-fluid">
        <div class="card-header">
          <div class="card-title">
            <h2>{{ordersConstants.ORDER_DETAILS}} (#{{orderId}})</h2>
          </div>
        </div>
        <div class="card-body pt-0" v-if="orderDetails.value">
          <div class="table-responsive">
            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody class="fw-bold text-gray-600">
                <tr v-if="!!orderDetails.value.orderstatus && orderDetails.value.orderstatus.title">
                  <td class="text-muted">
                    <div class="d-flex align-items-center"><i class="fas fa-truck me-2 fs-2 d-none"></i>{{ordersConstants.ORDER_STATUS}}</div>
                  </td>
                  <td class="fw-bolder text-end"><span class="badge badge-light-success">{{ orderDetails.value.orderstatus.title }}</span></td>
                </tr>
                <tr v-if="!!orderDetails.value.order_date">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.ORDER_DATE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{ formatDateTime(orderDetails.value.order_date) }}</td>
                </tr>
                <tr v-if="!!orderDetails.value.order_due_date">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.ORDER_DUE_DATE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatDate(orderDetails.value.order_due_date)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.order_due_date">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.PAYMENT_STATUS}}</div>
                  </td>
                  <td class="fw-bolder text-end"><span class="badge badge-light-success">{{orderDetails.value.payment_details.payment_status}}</span></td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.total_amount">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.ORDER_AMOUNT}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{ formatPrice(orderDetails.value.payment_details.total_amount) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--end::Order details-->
      <!--begin::Customer details-->
      <div class="card card-flush py-4 flex-row-fluid" v-if="orderDetails.value">
        <div class="card-header">
          <div class="card-title">
            <h2>{{ordersConstants.CUSTOMER_DETAILS}}</h2>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody class="fw-bold text-gray-600">
                <tr v-if="!!orderDetails.value.customer_name">
                  <td class="text-muted">
                    <div class="d-flex align-items-center"><i class="fas fa-truck me-2 fs-2 d-none"></i>{{ordersConstants.CUSTOMER}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{ orderDetails.value.customer_name }}</td>
                </tr>
                <tr v-if="!!orderDetails.value.customer_email">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.EMAIL}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.customer_email}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.customer_phone">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.PHONE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.customer_phone}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.customer_company">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.COMPANY}}:</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.customer_company}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <!--end::Customer details-->

      <!--begin::Payment & Shipping-->
      <div class="card card-flush py-4 flex-row-fluid" v-if="orderDetails.value && (orderDetails.value.payment_details || orderDetails.value.shipping_type)">
        <div class="card-header">
          <div class="card-title">
              <h2>{{ordersConstants.PAYMENT_AND_SHIPPING}}</h2>
          </div>
        </div>
        <div class="card-body pt-0">
          <div class="table-responsive">
            <table class="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
              <tbody class="fw-bold text-gray-600">
                <tr v-if="!!orderDetails.value.payment_details.payment_method">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.PAYMENT_METHOD}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.payment_details.payment_method}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.payment_date">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.PAYMENT_DATE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{ formatDate(orderDetails.value.payment_details.payment_date) }}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.transaction_id">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.TRANSACTION_ID}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.payment_details.transaction_id}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.discount">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Discount</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.discount)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.payment_processing_fees">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Payment Processing Fees</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.payment_processing_fees)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.shipping_charges">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Shipping Charges</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.shipping_charges)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.tax">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Tax</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.tax)}}</td>
                </tr>
                 <tr v-if="!!orderDetails.value.payment_details.subtotal">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Subtotal</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.subtotal)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.payment_details.total_amount">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">Total Amount</div>
                  </td>
                  <td class="fw-bolder text-end">{{formatPrice(orderDetails.value.payment_details.total_amount)}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.shipping_method">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.SHIPPING_METHOD}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.shipping_method}}</td>
                </tr>
                <tr v-if="!!orderDetails.value.shipping_type">
                  <td class="text-muted">
                    <div class="d-flex align-items-center">{{ordersConstants.SHIPPING_TYPE}}</div>
                  </td>
                  <td class="fw-bolder text-end">{{orderDetails.value.shipping_type}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!--end::Payment & Shipping-->

    <!--begin::Tab content-->
    <div class="tab-content">
      <!--begin::Tab pane-->
      <div class="tab-pane fade show active" id="kt_ecommerce_sales_order_summary" role="tab-panel">        
        <div class="d-flex flex-column gap-7 gap-lg-10" v-if="orderDetails.value">
          <!--begin::Address Block-->
          <div class="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
            <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value.billing_address">
              <div class="position-absolute top-0 end-0 opacity-10 pe-none text-end">
                <img src="/media/icons/duotune/ecommerce/ecm001.svg" class="w-175px">
              </div>
              <div class="card-header">
                <div class="card-title">
                  <h2>{{ordersConstants.BILLING_ADDRESS}}</h2>
                </div>
              </div>
              <div class="card-body pt-0" v-if="orderDetails.value.billing_address">
                <span v-if="orderDetails.value.billing_address.name">{{orderDetails.value.billing_address.name}}<br></span>
                <span v-if="orderDetails.value.billing_address.address1">{{orderDetails.value.billing_address.address1}}<br></span>
                <span v-if="orderDetails.value.billing_address.address2">{{orderDetails.value.billing_address.address2}}, </span>{{orderDetails.value.billing_address.city}}<br>
                <span v-if="orderDetails.value.billing_address.state">{{orderDetails.value.billing_address.state}}<br></span>
                <span v-if="orderDetails.value.billing_address.country">{{orderDetails.value.billing_address.country}} -</span><span v-if="orderDetails.value.billing_address.postcode">{{orderDetails.value.billing_address.postcode}}<br></span>
                <span v-if="!!orderDetails.value.billing_address.phone">{{ordersConstants.PHONE_NUMBER}}: </span> {{orderDetails.value.billing_address.phone}}<br>
                <span v-if="!!orderDetails.value.billing_address.company">{{ordersConstants.COMPANY}}: </span> {{orderDetails.value.billing_address.company}}
              </div>
            </div>
            <div class="card card-flush py-4 flex-row-fluid overflow-hidden"  v-if="orderDetails.value.shipping_address">
              <div class="position-absolute top-0 end-0 opacity-10 pe-none text-end">
                <img src="/media/icons/duotune/ecommerce/ecm006.svg" class="w-175px">
              </div>
              <div class="card-header">
                <div class="card-title">
                  <h2>{{ordersConstants.SHIPPING_ADDRESS}}</h2>
                </div>
              </div>
              <div class="card-body pt-0" v-if="orderDetails.value.shipping_address">
                <span v-if="orderDetails.value.shipping_address.name">{{orderDetails.value.shipping_address.name}}<br></span>
                <span v-if="orderDetails.value.shipping_address.address1">{{orderDetails.value.shipping_address.address1}}<br></span>
                <span v-if="orderDetails.value.shipping_address.address2">{{orderDetails.value.shipping_address.address2}}, </span>{{orderDetails.value.shipping_address.city}}<br>
                <span v-if="orderDetails.value.shipping_address.state">{{orderDetails.value.shipping_address.state}}<br></span>
                <span v-if="orderDetails.value.shipping_address.country">{{orderDetails.value.shipping_address.country}} -</span><span v-if="orderDetails.value.shipping_address.postcode"> {{orderDetails.value.shipping_address.postcode}}<br></span>
                <span v-if="!!orderDetails.value.shipping_address.phone">{{ordersConstants.PHONE_NUMBER}}: </span> {{orderDetails.value.shipping_address.phone}}<br>
                <span v-if="!!orderDetails.value.shipping_address.company">{{ordersConstants.COMPANY}}: </span> {{orderDetails.value.shipping_address.company}}
              </div>
            </div>
            <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value.blind_shipping == 1">
              <div class="position-absolute top-0 end-0 opacity-10 pe-none text-end">
                <img src="/media/icons/duotune/ecommerce/ecm006.svg" class="w-175px">
              </div>
              <div class="card-header">
                <div class="card-title">
                  <h2>{{ordersConstants.BLIND_SHIPPING_ADDRESS}}</h2>
                </div>
              </div>
              <div class="card-body pt-0">
                <span v-if="orderDetails.value.blind_shipping_address.name">{{orderDetails.value.blind_shipping_address.name}}<br></span>
                <span v-if="orderDetails.value.blind_shipping_address.address1">{{orderDetails.value.blind_shipping_address.address1}}<br></span>
                <span v-if="orderDetails.value.blind_shipping_address.address2">{{orderDetails.value.blind_shipping_address.address2}}, </span><span v-if="orderDetails.value.blind_shipping_address.city">{{orderDetails.value.blind_shipping_address.city}}<br></span>
                <span v-if="orderDetails.value.blind_shipping_address.state">{{orderDetails.value.blind_shipping_address.state}}<br></span>
                <span v-if="orderDetails.value.blind_shipping_address.country">{{orderDetails.value.blind_shipping_address.country}} -</span><span v-if="orderDetails.value.blind_shipping_address.postcode"> {{orderDetails.value.blind_shipping_address.postcode}}<br></span>
                <span v-if="!!orderDetails.value.blind_shipping_address.phone">{{ordersConstants.PHONE_NUMBER}}: </span> {{orderDetails.value.blind_shipping_address.phone}}<br>
                <span v-if="!!orderDetails.value.blind_shipping_address.company">{{ordersConstants.COMPANY}}: </span> {{orderDetails.value.blind_shipping_address.company}}
              </div>
            </div>
          </div>
          <!--end::Address Block-->

          <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value">
            <div class="card-header">
              <div class="card-title">
                <h2>{{ordersConstants.ORDER_ITEMS}}</h2>
              </div>
            </div>
            <div class="card-body pt-0">
                <Datatable
                  :table-data="tableData"
                  :table-header="tableHeader"
                  :empty-table-text="noDataFound"
                  :loading="loading"
                  :enable-items-per-page-dropdown="false"
                >
                  <template v-slot:cell-image="{ row: data }">
                    <el-image
                      style="width: 100px; height: 100px"
                      :src="data.product_image[0]"
                      :preview-src-list="data.product_image"
                      :initial-index="0"
                      :hide-on-click-modal=true
                      @click="stopScrolling()"
                      @close="closeImage()"
                      fit="cover"
                    >
                      <template #error>
                        <div class="image-slot">
                          <el-image style="width: 100px; height: 100px" :src="'/media/no-img.jpg'" fit="cover"/>
                        </div>
                      </template>
                    </el-image>
                  </template>
                  <template v-slot:cell-productDetails="{ row: data }">
                    <div class="d-flex align-items-center">
                      <div >
                        <a href="javascript:void(0)" @click.prevent="openDetailPage(orderId, data.product_id)">{{ data.product_name }}</a>
                        <div class="fs-7 text-muted" v-if="!!data.product_sku"><span class="text-gray-800">{{generalConstants.SKU}}:</span>
                            {{ data.product_sku }}
                        </div>
                        <div class="fs-7 text-muted" v-if="!!data.separated_product_sku"><span class="text-gray-800">{{constants.product.PRODUCT_SKU}}:</span>
                            <el-tooltip class="box-item" effect="dark" :content="data.sku_status == 'Matched' ? 'Mapped with: ' + data.reference_sku_code : data.sku_status == 'Unmatched' ? 'SKU not found in system!' : 'SKU rejected'" placement="top" >
                            <span :class="data.sku_status == 'Matched' ? 'badge badge-light-success' : data.sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ data.separated_product_sku }}</span>
                          </el-tooltip>
                          <el-tooltip class="box-item" effect="dark" :content="data.sku_status == 'Matched' ? 'Mapped with: ' + data.reference_sku_code : data.sku_status == 'Unmatched' ? 'SKU not found in system!' : 'SKU rejected'" placement="top" raw-content>
                            <i class="bi bi-question-circle-fill"></i>
                          </el-tooltip>
                        </div>
                        <div class="fs-7 text-muted" v-if="!!data.separated_art_sku && data.art_sku_status != 'NotFound'"><span class="text-gray-800">{{constants.product.ART_SKU}}:</span>
                            <template v-if="data.separated_art_sku && data.art_sku_status != 'NotFound'">
                            <el-tooltip class="box-item" effect="dark" :content="data.art_sku_status == 'Matched' ? 'Mapped with: ' + data.reference_art_sku_code : data.art_sku_status == 'Unmatched' ? 'Art SKU not found in system!' : 'SKU rejected'" placement="top" ><span :class="data.art_sku_status == 'Matched' ? 'badge badge-light-success' : data.art_sku_status == 'Unmatched' ? 'badge badge-light-warning' : 'badge badge-light-danger'">{{ data.separated_art_sku }}</span></el-tooltip>
                            </template>
                            <template v-else>
                                {{ '-' }}
                            </template>
                            <template v-if="data.art_sku_status != 'NotFound'">
                            <el-tooltip v-if="data.separated_art_sku" class="box-item" effect="dark" :content="data.art_sku_status == 'Matched' ? 'Mapped with: ' + data.reference_art_sku_code : data.art_sku_status == 'Unmatched' ? 'Art SKU not found in system!' : 'SKU rejected'" placement="top" raw-content>
                              <i class="bi bi-question-circle-fill"></i>
                            </el-tooltip>
                            </template>
                        </div>
                        <div class="fs-7 text-muted" v-if="!!data.product_weight">{{ordersConstants.WEIGHT}}: {{ data.product_weight }}</div>
                      </div>
                    </div>
                  </template>
                  <template v-slot:cell-additionalInfo="{ row: data }">
                    <div v-if="data.product_additional_info.length > 0">
                      <div v-for="(optionsData, i) in data.product_additional_info" :key="i">
                        <dl class="row mb-0">
                          <dt class="col-sm-6 text-right pl-0 mb-0 pr-0"><span v-html="optionsData.option_title"></span> :</dt>
                          <dd class="col-sm-6 mb-0"><span v-html="optionsData.option_value"></span></dd>
                        </dl>
                      </div>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </template>
                  <template v-slot:cell-shippingTypeAndMethods="{}">
                    <span v-if="orderDetails.value.shipping_method || orderDetails.value.shipping_type">
                    <span>{{ orderDetails.value.shipping_method }} </span><br><span>{{ orderDetails.value.shipping_type }}</span>
                    </span>
                    <span v-else>-</span>
                  </template>
                  <template v-slot:cell-status="{ row: data }">
                    <span :class="'badge badge-light-' + data.order_product_status_badge">{{data.order_product_status_title}}</span>
                  </template>
                  <template v-slot:cell-quantity="{ row: data }">
                    <span class="badge bg-primary">{{ data.product_quantity }}</span>
                  </template>
                  <template v-slot:cell-price="{ row: data }">
                    {{ formatPrice(data.product_price) }}
                  </template>
                  <template v-slot:cell-actions="{ row: data }">
                    <template v-if="data.sku_status != 'Rejected' && data.sku_status == 'Unmatched' && data.separated_product_sku != null && data.separated_product_sku != ''">
                        <el-tooltip class="box-item me-2" effect="dark" content="Approve SKU" placement="top" >
                          <button data-bs-target="#approve_product" @click="setOrderProduct(data.product_id, data.separated_product_sku, tableData.findIndex((i) => i.product_id === data.product_id),data.art_sku_status)" data-bs-toggle="modal" class="btn btn-icon btn-light btn-sm border-0 me-1"><i class="bi bi-check" style="color:blue;font-size:16px"></i></button>
                        </el-tooltip>
                        <el-tooltip class="box-item" effect="dark" content="Reject SKU" placement="top" >
                          <button @click.prevent="reject(data.product_id, tableData.findIndex((i) => i.product_id === data.product_id))" class="btn btn-icon btn-light btn-sm border-0 me-1"><i class="bi bi-x" style="color:red;font-size:16px"></i></button>
                        </el-tooltip>
                    </template>
                    <template v-if="data.sku_status != 'Rejected' && data.reference_sku_code == null">
                      <el-tooltip class="box-item" effect="dark" content="Edit Partner SKU" placement="top" >
                        <button data-bs-target="#edit_partner_sku" @click="setOrderProductforSKU(data.product_id, tableData.findIndex((i) => i.product_id === data.product_id),'productsku',data.separated_product_sku)" data-bs-toggle="modal" class="btn btn-icon btn-light btn-sm border-0 me-1"><i class="bi bi-pencil" style="color:blue;font-size:16px"></i></button>
                      </el-tooltip>
                    </template>
                    <el-tooltip class="box-item" effect="dark" content="Download Files" placement="top">
                      <span v-if="loadingdownload" class="spinner-border spinner-border-sm align-middle ms-2"></span>
                      <button v-if="data.is_files_downloaded == 0" type="button" @click="downloadFiles(orderId, data.product_id, tableData.findIndex((i) => i.product_id === data.product_id))" class="btn btn-icon btn-light btn-sm">
                        <i class="bi bi-file-earmark-arrow-down"></i>
                      </button>
                    </el-tooltip>
                  </template>
                  <template v-slot:cell-art_actions="{ row: data }">
                    <template v-if="data.art_sku_status != 'Rejected' && data.art_sku_status == 'Unmatched' && data.separated_art_sku != null">
                      <el-tooltip class="box-item me-2" effect="dark" content="Approve Art SKU" placement="top" >
                        <button data-bs-target="#approve_art_product" @click="setArtOrderProduct(data.product_id, data.separated_art_sku, tableData.findIndex((i) => i.product_id === data.product_id),data.sku_status)" data-bs-toggle="modal" class="btn btn-icon btn-light btn-sm border-0 me-1"><i class="bi bi-check" style="color:blue;font-size:16px"></i></button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="Reject Art SKU" placement="top" >
                        <button @click.prevent="rejectArtSku(data.product_id, tableData.findIndex((i) => i.product_id === data.product_id))" class="btn btn-icon btn-light btn-sm border-0 me-1"><i class="bi bi-x" style="color:red;font-size:16px"></i></button>
                      </el-tooltip>
                    </template>
                    <template v-if="data.reference_art_sku_code == null && data.art_sku_status != 'NotFound'">
                    <el-tooltip class="box-item" effect="dark" content="Edit Partner SKU" placement="top" >
                      <button data-bs-target="#edit_partner_sku" @click="setOrderProductforSKU(data.product_id, tableData.findIndex((i) => i.product_id === data.product_id),'artsku',data.separated_art_sku)" data-bs-toggle="modal" class="btn btn-icon btn-light btn-sm border-0"><i class="bi bi-pencil" style="color:blue;font-size:16px"></i></button>
                    </el-tooltip>
                    </template>
                    <template v-if="data.art_sku_status == 'NotFound' || data.art_sku_status == 'Matched'">
                      -
                    </template>
                  </template>
                </Datatable>
            </div>
          </div>
          <div class="card card-flush py-4 flex-row-fluid overflow-hidden" v-if="orderDetails.value && orderDetails.value.extra_fields">
            <div class="card-header">
              <div class="card-title">
                <h2>{{ordersConstants.ORDER_EXTRA_FIELDS}}</h2>
              </div>
            </div>
            <div class="card-body pt-0">
              <div class="d-flex flex-wrap">
                <div class="flex-equal me-5">
                  <table class="table table-flush fw-bold gy-1">
                    <tbody>
                      <template v-for="(value, key) in orderDetails.value.extra_fields" :key="key">
                        <tr v-if="value && value != null && value != ''">
                            <td class="text-muted min-w-200px w-200px">{{formatText(key)}} : </td>
                            <td class="text-gray-800">
                            <template v-if="typeof value === 'object'">
                              <span v-for="(item, itemkey) in value" :key="itemkey">
                                <template v-if="typeof item === 'object'">
                                  <span v-for="(subitem, subitemkey) in item" :key="subitemkey">
                                    <template v-if="typeof subitem === 'object'">
                                      <span v-for="(subitem1, subitemkey1) in subitem" :key="subitemkey1">
                                        {{formatText(subitemkey1)}} : {{(subitem1) ? subitem1 : '-'}} <br>
                                      </span>
                                    </template>
                                    <template v-else>
                                      {{formatText(subitemkey)}} : {{(subitem) ? subitem : '-'}} <br>
                                    </template>
                                  </span>
                                </template>
                                <template v-else>
                                  {{formatText(itemkey)}} : {{(item) ? item : '-'}} <br>
                                </template>
                              </span>
                            </template>
                            <template v-else>
                                {{value}}
                            </template>
                            </td>
                        </tr>
                      </template>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Tab pane-->
      <!--begin::Tab pane-->
      <div class="tab-pane fade" id="kt_ecommerce_sales_order_history" role="tab-panel">
        <!--begin::Orders-->
        <div class="d-flex flex-column gap-7 gap-lg-10">
          <!--begin::Order history-->
          <div class="card card-flush py-4 flex-row-fluid" v-if="orderDetails.value">
            <!--begin::Card header-->
            <div class="card-header">
              <div class="card-title">
                <h2>Order History</h2>
              </div>
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
              <div class="table-responsive">
                <!--begin::Table-->
                <Datatable
                  :table-data="orderDetails.value.order_history"
                  :table-header="orderHistoryTableHeader"
                  :empty-table-text="noDataFound"
                  :enable-items-per-page-dropdown="true"
                >
                  <template v-slot:cell-created_at="{ row: data }">
                    {{ formatDateTime(data.created_at) }}
                  </template>
                  <template v-slot:cell-comment="{ row: data }">
                    <div v-if="data.order_product || data.order_product_status">
                      <span class="text-primary" v-if="data.order_product.product_name">
                        {{ data.order_product.product_name }}
                      </span>
                      <span class="text-primary" v-else>
                        NA
                      </span>
                      <span class="text-primary" v-if="data.order_product_status">
                         - {{ data.order_product_status.title }}
                      </span>
                    </div>
                    <span>
                      {{ data.comment }}
                    </span>
                  </template>
                  <template v-slot:cell-created_by="{ row: data }">
                    {{ data.user.name }}
                  </template>
                </Datatable>
                <!--end::Table-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end::Tab pane-->
    </div>
    <ApproveProductModal :orderProductId="orderProductId" :orderId="orderId" :product="productData" :wholeproductData="tableMasterData" :isApproveArtSKU="isApprovedArtSKU" :platform="platform" :productSku="productSku"  @statusUpdate="setProductInfo"></ApproveProductModal>
    <ApproveArtProductModal :orderProductId="orderProductId" :separatedArtSku="separatedArtSku" :isApprovedProductSKU="isApprovedProductSKU" @statusUpdate="setArtProductInfo"></ApproveArtProductModal>
    <EditPartnerSKU :orderProductId="orderProductId" :orderId="orderId" :platform="platform" :editSkuType="editSkuType" :separatedProductSku="separatedProductSku" :separatedArtSku="separatedArtSku" ref="updateProps" @skuUpdate="setProductSKU"></EditPartnerSKU>
  </div>
</template>
<script>
import { reactive, ref, onMounted } from 'vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { formatPrice, formatDate, formatDateTime, formatText } from "@/core/helpers/common";
import { ElLoading, ElMessageBox } from 'element-plus';
import { useRouter } from "vue-router";
import { createOrderPoductOptions, createOrderPoductFiles } from "@/composable/order.js";
import ApproveProductModal from "@/components/modals/forms/ApproveProductModal.vue";
import ApproveArtProductModal from "@/components/modals/forms/ApproveArtProductModal.vue";
import EditPartnerSKU from "@/components/modals/forms/EditPartnerSKU.vue";
import { notificationFire } from "@/composable/notification.js";
import globalConstant from "@/core/data/globalConstant.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import useEventBus from '../../composable/useEventBus'
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";
import { loggedInUserRoles, loggedInUserPermissions } from "@/composable/get-roles.js";

export default {
  name: "order-detail",
  components: {
    ApproveProductModal,
    ApproveArtProductModal,
    Datatable,
    EditPartnerSKU
  },
  
  setup() {
    const { onEvent, emitEvent } = useEventBus()
    const router = useRouter();
    const orderId = ref(0);
    const orderDetails = reactive({});
    const route = useRoute();
    const fullscreenLoading = ref(false);
    const tableData = ref([]);
    const noDataFound = ref("No Data Found!");
    const loading = ref(false);
    const searchParams = reactive({
      page_type: "pending_order_details",
    })

    const fullScreenLoading = ref(false);
    const productData = reactive([]);
    const tableMasterData = ref({});
    const orderProductId = ref(0);
    const separatedProductSku = ref("");
    const separatedArtSku = ref("");
    const updatedProduct = ref(0);
    const orderProductIndex = ref(0);
    const platform = ref("0");
    const productSku = ref("");
    const generalConstants = globalConstant.general;
    const ordersConstants = globalConstant.orders;
    const constants = globalConstant;
    const editSkuType = ref('');
    const isApprovedProductSKU = ref('');
    const isApprovedArtSKU = ref('');
    const permission = reactive({
      isReadOrderHistoryAllowed: false,
    })
    const updateProps = ref(null);
    const loadingdownload = ref(false);
    const tableHeader = ref([
      {
        name: generalConstants.SRNO,
        key: "index",
        sortable: false,
        width: "w-10px",
        alignment: 'text-center'
      },
      {
        name: generalConstants.IMAGE,
        key: "image",
        sortable: false,
        width: "w-150px",
        alignment: 'text-center'
      },
      {
        name: ordersConstants.PRODUCT_DETAILS,
        key: "productDetails",
        sortable: false,
        width: "w-300px",
      },
      {
        name: ordersConstants.ADDITIONAL_INFORMATION,
        key: "additionalInfo",
        width: "w-250px",
        sortable: false,
      },
      {
        name: "Shipping Type & Method",
        key: "shippingTypeAndMethods",
        width: "w-250px",
        sortable: false,
      },
      {
        name: generalConstants.STATUS,
        key: "status",
        sortable: false,
        width: "w-150px"
      },
      {
        name: generalConstants.QUANTITY,
        key: "quantity",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.PRICE,
        key: "price",
        sortable: false,
        alignment: 'text-center'
      },
      {
        name: generalConstants.ACTIONS,
        key: "actions",
        sortable: false,
        width: "w-200px",
        alignment: 'text-center'
      },
      {
        name: 'Art Actions',
        key: "art_actions",
        sortable: false,
        width: "w-150px"
      },
    ]);

    //Order History table header data
    const orderHistoryTableHeader = ref([
      {
        name: 'Date Added',
        key: "created_at",
        sortable: true,
        width: "w-100px"
      },
      {
        name: 'Comment',
        key: "comment",
        sortable: false,
        width: "w-300px"
      },
      {
        name: 'Added By',
        key: "created_by",
        sortable: true,
        width: "w-100px"
      }
    ]);

    const tableRowClassName = (row) => {
      if (route.params.product_id == row.row.product_id) {
        return 'success-row'
      }
      return ''
    }

    const getOrderDetails = async () => {
      fullscreenLoading.value = true;
      await ApiService.query("pending-orders/"+orderId.value, { params: searchParams })
      .then(({ data }) => {
        orderDetails.value = data.data;
        platform.value = orderDetails.value.platform;
        let orderProductItems = data.data.order_products;
        tableData.value.splice(0, tableData.value.length, ... data.data.order_products);
        if (orderProductItems.length > 0) {
          orderProductItems.forEach((value) => {
            value['product_additional_info'] = createOrderPoductOptions(value)['productAdditionalInfo'];
            value["product_image"] = createOrderPoductFiles(value)['productFilesInfo'];
          });
        }
        fullscreenLoading.value = false;
      })
      .catch((error) => {
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          if (status == 404) {
            router.push({ name: "pending-orders" });
          } else {
            notificationFire(message, 'error');
          }
        } 
      });
    }

    const setOrderProduct = (productId, sku, index, artSkuStatus) => {
      orderProductId.value = productId;
      productSku.value = sku;
      orderProductIndex.value = index;
      isApprovedArtSKU.value = artSkuStatus;
    }

    const setArtOrderProduct = (productId, separateArtSku, index,productSkuStatus) => {
      orderProductId.value = productId;
      separatedArtSku.value = separateArtSku;
      orderProductIndex.value = index;
      isApprovedProductSKU.value = productSkuStatus;
    }

    const setOrderProductforSKU = (productId, index, skuType, sku) => {
      orderProductId.value = productId;
      orderProductIndex.value = index;
      editSkuType.value = skuType;
      if(skuType == 'artsku'){
        separatedArtSku.value = sku;  
        updateProps.value.updateProps(separatedArtSku.value);
      }else{
        separatedProductSku.value = sku;
        updateProps.value.updateProps(separatedProductSku.value);
      }
    }

    let scroll = function (e) { e.preventDefault() };

    const stopScrolling = () => {    
      document.addEventListener("wheel", scroll, { passive:false })
    }

    const closeImage = () => {
      document.removeEventListener("wheel", scroll)
    }

    const downloadFiles = (orderId, orderProductId, index) => {
      loadingdownload.value = true;
      ApiService.query("order-files-download/"+orderId+"/"+orderProductId)
      .then(({ data }) => {
        if (data.status) {
          tableData.value[index].is_files_downloaded = data.is_files_downloaded;
          if(data.imageData.success){
            if(data.imageData.success['P'+orderProductId].page1.image){
              tableData.value[index].product_image.push(process.env.VUE_APP_API_URL+"../../../orders/"+orderId+'/'+data.imageData.success['P'+orderProductId].page1.image);
            }
            if(data.imageData.success['P'+orderProductId].page1.thumb){
              tableData.value[index].product_image.push(process.env.VUE_APP_API_URL+"../../../orders/"+orderId+'/'+data.imageData.success['P'+orderProductId].page1.thumb);
            }
          }
          notificationFire(data.msg, data.alertType);
        } else {
          notificationFire(data.msg, data.alertType);
        }
        loadingdownload.value = false;
      })
      .catch((error) => {
        loadingdownload.value = false;
        let response = error.response;
        if (response) {
          let status = response.status;
          let message = response.data.message;
          if (status == 404) {
            router.push({ name: "orders" });
          } else {
            notificationFire(message, "error");
          }
        } 
      });
    }

    //Get product and product releted data
    const getProductData = () => {
      fullScreenLoading.value = true;
      ApiService.query('get-active-products')
        .then(({ data }) => {
          if (data.data) {
            tableMasterData.value = [];
            let increment = 0;
            data.data.forEach((obj) => {
                productData.push({
                    product_id: obj.product_id,
                    product_name: obj.name,
                });
                tableMasterData.value[increment] = obj;
                increment++;
            });
          }
          fullScreenLoading.value = false;
        })
        .catch((error) => {
          let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404 || status == 422) {
                router.push({ name: 'pending-orders'})
              }
            }
            fullScreenLoading.value = false;
        });
    }

    const setProductInfo = (data) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if(tableData.value[i].separated_product_sku == productSku.value){
          tableData.value[i].sku_status = data.approval;
          tableData.value[i].reference_sku_code = data.reference_sku_code;
        }
      }
    }

    const setArtProductInfo = (data) => {
      for (let i = 0; i < tableData.value.length; i++) {
        if(tableData.value[i].separated_art_sku == separatedArtSku.value){
          tableData.value[i].art_sku_status = data.approval;
          tableData.value[i].reference_art_sku_code = data.reference_art_sku_code;
        }
      }
    }

    const setProductSKU = (data) => {
      if(editSkuType.value == 'productsku'){
        tableData.value[orderProductIndex.value].separated_product_sku = data.sku;
        tableData.value[orderProductIndex.value].sku_status = data.status;
        tableData.value[orderProductIndex.value].reference_sku_code = data.referenceCode;
      }else{
        tableData.value[orderProductIndex.value].separated_art_sku = data.artsku;
        tableData.value[orderProductIndex.value].art_sku_status = data.status;
        tableData.value[orderProductIndex.value].reference_art_sku_code = data.referenceCode;
        if (tableData.value[orderProductIndex.value].sku_status == 'Matched' && (tableData.value[orderProductIndex.value].art_sku_status ==  'Matched' || tableData.value[orderProductIndex.value].art_sku_status == 'NotFound')) {
          tableData.value.splice(orderProductIndex.value, 1);
        }
      }
    }

    const reject = async (orderProductId, index) => {
      ElMessageBox.confirm(
        'Do you really want to reject this product? Once the any of the order product will be rejected then the order will be pending only.',
        'Warning',
        {
          confirmButtonText: 'Reject',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
      .then(() => {
        ApiService.post("reject-product", {orderProductId: orderProductId, orderId: orderId.value})
        .then((response) => {
          let status = response.data.status;
          let message = response.data.message;
          if (status) {
            notificationFire(message, 'success');
            tableData.value[index].sku_status = response.data.data;
          }
        })
        .catch((error) => {
          let message = error.message;
          notificationFire(message, 'error');
        });
      })
    }

    const rejectArtSku = async (orderProductId, index) => {
      ElMessageBox.confirm(
        'Do you really want to reject this art SKU?',
        'Warning',
        {
          confirmButtonText: 'Reject',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }
      )
      .then(() => {
        ApiService.post("reject-art-product", {orderProductId: orderProductId, orderId: orderId.value})
        .then((response) => {
          let status = response.data.status;
          let message = response.data.message;
          if (status) {
            notificationFire(message, 'success');
            tableData.value[index].art_sku_status = response.data.data;
          }
        })
        .catch((error) => {
          let message = error.message;
          notificationFire(message, 'error');
        });
      })
    }

    let fromRoutePath = "pending-order-products";
    let fromRouteName = "Pending Order Products";
    if(route.name == "pendingOrderDetails"){
        fromRoutePath = "pending-orders";
        fromRouteName = "Pending Orders";
    }

    const openDetailPage = (orderId,orderProductId) => {
      router.push('/'+ fromRoutePath + '/product/'+orderId+'/'+orderProductId)
    }

    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "back") {
        router.back();
      }
    });
    
    onMounted(() => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" :"Pending Orders", 'path': 'pending-orders'}, { name: formatText(route.name), active: true }]);
      let role = loggedInUserRoles();
      let per = loggedInUserPermissions();
      if (per.includes("readOrderHistory") || role == "Super Admin")
        permission.isReadOrderHistoryAllowed = true;
      getProductData();
      orderId.value = Number(route.params.id);
      getOrderDetails();
      let fromRoutePath = "pending-order-products";
      let fromRouteName = "Pending Order Products";
      if(route.name == "pendingOrderDetails"){
          fromRoutePath = "pending-orders";
          fromRouteName = "Pending Orders";
      }
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name" : fromRouteName, 'path': fromRoutePath}, { name: formatText(route.name), active: true }]);
      setCurrentPageActionButtons([{"name" :generalConstants.BACK, 'action': 'back','class': 'btn-primary','isLoader': false,'iconClass':"bi bi-arrow-left fs-4" }]);
    });

    return {
      orderId,
      loading,
      noDataFound,
      orderDetails,
      formatPrice,
      formatDate,
      formatDateTime,
      fullscreenLoading,
      tableData,
      tableRowClassName,
      getProductData,
      productData,
      tableMasterData,
      setOrderProduct,
      orderProductId,
      setProductInfo,
      orderProductIndex,
      reject,
      formatText,
      platform,
      generalConstants,
      ordersConstants,
      tableHeader,
      openDetailPage,
      setOrderProductforSKU,
      setProductSKU,
      orderHistoryTableHeader,
      permission,
      productSku,
      downloadFiles,
      separatedProductSku,
      separatedArtSku,
      setArtOrderProduct,
      rejectArtSku,
      setArtProductInfo,
      editSkuType,
      isApprovedProductSKU,
      isApprovedArtSKU,
      constants,
      updateProps,
      loadingdownload,
      stopScrolling,
      closeImage
    };
  },
};
</script>

<style lang="scss">
.el-table .success-row {
  color: #4E5155;
  background-color: #02bc7714;
}
.el-table td.el-table__cell div{
  padding: 0px;
}
.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.5em + 1.1rem + 2px);
  width: calc(1.5em + 1.1rem + 2px);
}
</style>