<style>
.text-capitalize .form-control {
text-transform: uppercase;
}
</style>
<template>
  <!--begin::Modal - Create Api Key-->
  <div class="modal fade" id="edit_partner_sku" ref="editPartnerSKURef" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="edit_partner_sku_header">
          <!--begin::Modal title-->
          <h2 v-if="editSkuType == 'productsku'">Edit Partner Product SKU</h2>
          <h2 v-if="editSkuType == 'artsku'">Edit Art SKU</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Form-->
        <el-form @submit.prevent="submit(formRef)" :model="apiData" ref="formRef" class="w-100">
          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-10 fv-row">
                <!--begin::Label-->
                <label v-if="editSkuType == 'productsku'" class="required fs-5 fw-bold mb-2">Enter Product SKU</label>
                <label v-if="editSkuType == 'artsku'" class="required fs-5 fw-bold mb-2">Enter Art SKU</label>
                <!--end::Label-->
                <template v-if="editSkuType == 'productsku'">
                  <el-form-item prop="sku" class="mb-0" :rules="[
                    {
                      required: true,
                      message: 'Product SKU is required',
                      trigger: 'change'
                    }]">
                    <el-input size="large" class="text-capitalize" v-model="apiData.sku" placeholder="Enter Product SKU"/> 
                  </el-form-item>
                </template> 
                <template v-else>
                  <el-form-item v-if="artSKUValidation" prop="sku" class="mb-2" :rules="[
                    {
                      required: true,
                      message: 'Art SKU is required',
                      trigger: 'change'
                    }]">
                    <el-input size="large" class="text-capitalize" v-model="apiData.sku" placeholder="Enter Art SKU"/> 
                  </el-form-item>
                   <el-form-item v-else prop="sku" class="mb-2">
                    <el-input size="large" class="text-capitalize" v-model="apiData.sku" placeholder="Enter Art SKU"/> 
                  </el-form-item>
                  <el-checkbox 
                    label="No Art SKU"
                    id="no_art_sku"
                    name="no_art_sku"
                    @change="checkIsNoArtSKUChecked($event)"
                  >
                  </el-checkbox>
                </template>
              </div>
              <!--end::Input group-->
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button ref="submitButtonRef" type="submit" value="approve" id="edit_partner_sku_submit" class="btn btn-primary">
              <span class="indicator-label"> Save </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
            <!--begin::Button-->
            <button type="reset" id="edit_partner_sku_cancel" @click="reset()" class="btn btn-secondary me-3" data-bs-dismiss="modal">Cancel</button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </el-form>
        <!--end::Form-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create Api Key-->
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "edit-partner-sku-modal",
  emit: ["skuUpdate"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    orderProductId: Number, 
    orderId: Number,
    platform: String,
    editSkuType: String
  },
  setup(props, { emit }) {
    const router = useRouter();
    const submitButtonRef = ref(null);
    const modalRef = ref(null);
    const formRef = ref(null);
    const artSKUValidation = ref(true);
    const editPartnerSKURef = ref(null);
    const fullScreenLoading = ref(false);
    const apiData = ref({
      sku:"",
    });

    const updateProps = (sku) => {
      apiData.value.sku = sku;
    }

    const submit = async (formEl) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          //Disable button
          submitButtonRef.value.disabled = true;
          // Activate indicator
          submitButtonRef.value.setAttribute("data-kt-indicator", "on");

          let formData = apiData.value;
          formData['orderId'] = props.orderId;
          formData['orderProductId'] = props.orderProductId;
          formData['platform'] = props.platform;
          formData['editSkuType'] = props.editSkuType;
          formData['isArtSKUAvailble'] = artSKUValidation;
          ApiService.post('update-partner-order-product-sku', formData)
          .then((response) => {
            if (response.data) {
              let status = response.status;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                notificationFire(message,'success');
              }
              if(response.data.data.sku) {
                emit("skuUpdate", response.data.data);
              }
              if(response.data.data.artsku || response.data.data.artsku == '') {
                emit("skuUpdate", response.data.data);
              }
              if(response.data.data.redirect) {
                router.push({ name: 'pending-orders'})
              }
            }
            apiData.value.sku = "";
            submitButtonRef.value.disabled = false;
            submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            hideModal(editPartnerSKURef.value);
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            else {
              let message = error.message;
              notificationFire(message,'error');
            }
            submitButtonRef.value.disabled = false;
            submitButtonRef.value.setAttribute("data-kt-indicator", "off");
          });
        }
      })
    };

    const checkIsNoArtSKUChecked = (event) => {
      if (event) {
        artSKUValidation.value = false;
      } else {
        artSKUValidation.value = true;
      }
    };

    const reset = () => {
      apiData.value.sku = "";
      document.querySelectorAll('.fv-plugins-message-container').forEach(function(el) {
        el.style.display = 'none';
      });
    }

    return {
      apiData,
      submit,
      submitButtonRef,
      modalRef,
      editPartnerSKURef,
      fullScreenLoading,
      reset,
      updateProps,
      formRef,
      checkIsNoArtSKUChecked,
      artSKUValidation
    };
  },
});
</script>