<template>
  <!--begin::Modal - Create Api Key-->
  <div class="modal fade" id="approve_art_product" ref="createApprovalModalRef" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header" id="approve_product_header">
          <!--begin::Modal title-->
          <h2>Approve Product</h2>
          <!--end::Modal title-->

          <!--begin::Close-->
          <div class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Form-->
        <Form id="approve_product_form" class="w-100" style="width: 100%" @submit="submit" :validation-schema="validationSchema">
          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div class="scroll-y me-n7 pe-7" id="approve_product_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#approve_product_header" data-kt-scroll-wrappers="#approve_product_scroll" data-kt-scroll-offset="300px">

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-10 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Select Art SKU</label>
                <!--end::Label-->
                <!--begin::Select-->
                <Field name="artSkuId" data-control="select2" data-hide-search="true" data-placeholder="Select Art SKU" class="form-select" as="select" v-model="apiData.artSkuId" @change="setArtSKU(apiData.artSkuId)">
                  <option value="">Select Art SKU</option>
                  <option v-for="SKUs in skuData.value" :value="SKUs.art_sku_id" :key="SKUs">
                    {{SKUs.art_sku_code}}
                  </option>
                </Field>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="artSkuId" />
                  </div>
                </div>
                <!--end::Select-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer">
            <!--begin::Button-->
            <button ref="submitButtonRef" type="submit" value="approve" id="approve_product_submit" class="btn btn-primary">
              <span class="indicator-label"> Save </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
            <!--begin::Button-->
            <button type="reset" id="approve_product_cancel" @click="reset()" class="btn btn-secondary me-3" data-bs-dismiss="modal">Cancel</button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create Api Key-->
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { notificationFire } from "@/composable/notification.js";
import { useRouter } from "vue-router";
import useEventBus from '../../../composable/useEventBus'

export default defineComponent({
  name: "art-product-approval-modal",
  emit: ["statusUpdate"],
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    orderProductId: Number,
    separatedArtSku: String,
    artSkuId: Number,
    isApprovedProductSKU: String
  },
  setup(props, { emit }) {
    const { emitEvent } = useEventBus()
    const router = useRouter();
    const submitButtonRef = ref(null);
    const modalRef = ref(null);
    const createApprovalModalRef = ref(null);
    const fullScreenLoading = ref(false);
    const skuData = reactive([]);
    const apiData = ref({
      artSkuId: "",
      artSkuCode: "",
      skuID:"",
    });

    const getArtSKUs = () => {
      fullScreenLoading.value = true;
      ApiService.post('get-art-sku-for-pending-order')
        .then(({ data }) => {
          if (data.data) {
            let skus = [];
            data.data.forEach((obj) => {
              skus.push({
                  art_sku_id: obj.art_sku_id,
                  art_sku_code: obj.art_sku_code,
              });
            });
            skuData.value = skus;
          }
          fullScreenLoading.value = false;
        })
        .catch((error) => {
          let response = error.response;
            if (response) {
              let status = response.status;
              let message = response.data.message;
              notificationFire(message, "error");
              if (status == 404 || status == 422) {
                router.push({ name: 'pending-orders'})
              }
            }
            fullScreenLoading.value = false;
        });
    }

    const setArtSKU = (val) => {
      var index = skuData.value.findIndex(p => p.art_sku_id == val);
      apiData.value.artSkuCode = skuData.value[index].art_sku_code;
    };

    const validationSchema = Yup.object().shape({
      artSkuId: Yup.string().required().label("Art SKU"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        let formData = apiData.value;
        formData['orderProductId'] = props.orderProductId;
        formData['separatedArtSku'] = props.separatedArtSku;
        formData['isApprovedProductSKU'] = props.isApprovedProductSKU;
        ApiService.post('approve-art-product', formData)
          .then((response) => {
            if (response.data) {
              let status = response.status;
              let message = response.data.message;
              if (status == 200 || status == 201) {
                notificationFire(message,'success');
              }
              if(response.data.data) {
                emit("statusUpdate", response.data.data);
              }
              if(response.data.data.redirect && props.isApprovedProductSKU == 'Matched') {
                getPendingOrdersCount();
                router.push({ name: 'pending-orders'})
              }
            }
            submitButtonRef.value.disabled = false;
            submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            hideModal(createApprovalModalRef.value);
          })
          .catch((error) => {
            let response = error.response;
            if (response) {
              let message = response.data.message;
              notificationFire(message, "error");
            }
            else {
              let message = error.message;
              notificationFire(message,'error');
            }
            submitButtonRef.value.disabled = false;
            submitButtonRef.value.setAttribute("data-kt-indicator", "off");
          });
    };

    const reset = () => {
      setTimeout(() => 
        apiData.value.artSkuId = "",
        apiData.value.artSkuCode = "", 2000);
      }

    const getPendingOrdersCount = async () => {
      await ApiService.post("/pending-orders-count", {})
      .then(({ data }) => {
        if (data.pendingOrderProduct  != undefined) {
          emitEvent('counterUpdate',{"pendingOrders":data.pendingOrders})
        }
        if (data.pendingOrderProduct  != undefined) {
          emitEvent('counterUpdate',{"pendingOrderProducts":data.pendingOrderProduct})
        }
      })
      .catch((error) => {
        console.log(error);
        //Toast the error here
      });
    }

    onMounted(() => {
      getArtSKUs();
    });

    return {
      apiData,
      validationSchema,
      submit,
      submitButtonRef,
      modalRef,
      createApprovalModalRef,
      getArtSKUs,
      fullScreenLoading,
      skuData,
      reset,
      getPendingOrdersCount,
      setArtSKU
    };
  },
});
</script>
